import React, { useState } from 'react';
import 'antd/dist/antd.min.css'
import { PlusCircleOutlined, QuestionCircleFilled, MessageFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import winners from '../shared/winners.json'
import questions from '../shared/questions.json';

type winnersPageType = {
    today: number
    recentMonth: number
}
type winner = {
    id: string
    name: string,
}

const WinnersPage: React.FC<winnersPageType> = ({ today, recentMonth }) => {
    const [clickDate, setClickDate] = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    function ShowQuestion(day: number) {
        setClickDate(day)
        setIsModalOpen(true)

    }
    return (
        <>
            <div className='flex-container'>
                <h4 className='text-white'>List of Winners</h4>
              
                {winners.length > 0 && winners.map((winner:winner , index) => (
                    // <div className='mb-10' key={index}><Button icon={<PlusCircleOutlined />} className="button-primary" size='small'>Day {index + 1}</Button> <span className='text-white'>{ winner.name}</span> </div>
                    <div className='mb-10' key={index}><Button icon={<PlusCircleOutlined />} className="button-primary"  onClick={() => ShowQuestion(index)}>Day {index + 1}</Button> <span className='text-white'>{winner.name}</span> </div>
                    )
                )}

            </div>
            <Modal title={`Question with answer for Day ${clickDate + 1}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }}>
                <div className='flex-container'>
                    <div><QuestionCircleFilled  twoToneColor="#00344d" style={{ fontSize: '20px', color: '#00344d', marginRight: '10px' }} /><span className='whiteSpace'>{questions[clickDate].question}</span></div>
                    <div><MessageFilled twoToneColor="#00344d" style={{ fontSize: '20px', color: '#00344d', marginRight: '10px' }} /><span>{questions[clickDate].answer}</span></div>
                </div>
            </Modal>

        </>

    )
}

export default WinnersPage