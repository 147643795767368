import React, { useState } from 'react';

import 'antd/dist/antd.min.css'
import './App.css';
import './Styles/style.css';

import { Layout, Row, Col, Modal, Form, Input, Select, Progress } from 'antd'

import companies from './shared/countries.json';
import questions from './shared/questions.json';
import WinnersPage from './Components/winnersPage';
import postAnswer from './shared/data';

const { Header, Content } = Layout;

const App: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedNumber, setClickedNumber] = useState(0)
  const [percent, setPercent] = useState(10)
  const [form] = Form.useForm();
  const current = new Date();
  const today = current.getDate();
  const recentMonth = current.getMonth() + 1;
  const checkedAttr = document.getElementsByClassName('checkBox') as HTMLCollectionOf<HTMLInputElement>;

  const suffledNumber = [13, 7, 8, 23, 17, 1, 2, 9, 14, 12, 21, 6, 3, 16, 11, 10, 18, 24, 15, 19, 4, 5, 22, 20]
  const showModal = (day: number) => {
    if (day === today && recentMonth === 12) {
      setClickedNumber(day)
      setIsModalOpen(true);
    }
    else {
      Modal.error({
        title: 'Oops!!!',
        content: `You have selected the wrong date. Please select today's date and try again! `,
        onOk() {
          for (let i = 0; i < checkedAttr.length; i++) {
            checkedAttr[i].checked = false;
          }
        }
      });

    }
  };
  const handleSubmit = async (values: any) => {
    setButtonDisabled(true)
    setIsLoading(true)
    setPercent(50)
    await postAnswer(values.answer, values.name, values.email, values.country, questions[clickedNumber - 1].question, today)
    setPercent(100)
    setIsLoading(false)
    setIsModalOpen(false);
    Modal.success({
      content: `Thanks for your answer. You will be contacted by e-mail if you are the winner of the day! `,
      onOk() {
        for (let i = 0; i < checkedAttr.length; i++) {
          checkedAttr[i].checked = false;
        }
      }
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    for (let i = 0; i < checkedAttr.length; i++) {
      checkedAttr[i].checked = false;
    }
  };

  return (
    <>
      <Layout >
        <Header className="header">
          <div className="logo"><img src='./logo.svg' alt='logo' /></div>
          <div className='title'>Christmas Calendar</div>
        </Header>
        <Layout className='App'>
          <Content
            className="site-layout-background min-ht-100"
            style={{
              padding: 24,
              margin: 0,

            }}
          >
            <Row>
              <Col xs={{ span: 23 }} lg={{ span: 13 }} offset={1} className="transparent-background">
                <Row><Col span={24} className="mb-10"> <span className='text-white '>Click on today's date and answer the question of the day!</span></Col></Row>
                <Row className="calendar blur">
                  {suffledNumber.map(day =>
                    <Col span={6} key={day} >
                      <label>
                        <input type="checkbox" className="checkBox" />
                        <div className="door">
                          <div className="front">{day}</div>
                          <div className="back"></div>
                        </div>
                        <div className="inside" >
                          {(day === today && recentMonth === 12) ?
                            <img src={require('./Images/gift.jpg')} onClick={() => showModal(day)} className="xmas-image" alt="gift" /> :
                            <img src={require(`./Images/img${day}.jpg`)} onClick={() => showModal(day)} className="xmas-image" alt={`day${day}`} />

                          }
                        </div>

                      </label>
                    </Col>

                  )}
                </Row>
                <Modal title={clickedNumber > 0 ? questions[clickedNumber - 1].question : ""} open={isModalOpen} onOk={form.submit} onCancel={handleCancel} okText="Send Answer" className="whiteSpace" okButtonProps={{ disabled: isButtonDisabled }}>

                  <Form form={form} layout="horizontal" onFinish={handleSubmit} >
                    <Form.Item label="Answer" name="answer" rules={[{ required: true, message: 'Please input your answer!' }]}>
                      <Input />
                    </Form.Item>

                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                      <Input />
                    </Form.Item>

                    <Form.Item label="Country" name="country" rules={[{ required: true, message: 'Please select MW Company' }]}>
                      <Select>
                        {companies.map((country, index) =>
                          (<Select.Option value={country.name} key={index}>{country.name}</Select.Option >))}
                      </Select>
                    </Form.Item>
                    {isLoading &&
                      <Progress percent={percent} size="small" status="active" />
                    }
                  </Form>

                </Modal>

              </Col>

              <Col xs={{ span: 23 }} lg={{ span: 9 }} offset={1} className="transparent-background">
                <WinnersPage today={today} recentMonth={recentMonth}></WinnersPage></Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default App;
