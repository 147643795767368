import API from './config'
const postAnswer = async function (answer, name, email, country, question, day) {
    const apiUrl = API +'/answer-post'
  
    const createNewAnswer = {
      answer: answer,
      name: name,
      email: email,
      country: country,
      question: question,
      day: day
    }
  
    const res = await fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify(createNewAnswer)
    })
  
    if (res.ok) {
      return res.ok
    }
  }
  export default postAnswer